import React, {useState, useEffect, useContext} from 'react'
import AdminPanelAside from '../../../components/AdminPanelAside/AdminPanelAside'
import { Navigate } from 'react-router-dom';
import "./newProduct.scss"
import axios from 'axios';
import LoadingPage from '../../Loading/LoadingPage';
import { MainContext } from '../../../App';
import MultilevelCategoryMenu from '../../../components/NestedCatMenu/MultilevelCategoryMenu';

export default function AddNewProduct() {

    const [
        isLoading,
        setIsLoading,
        userRole,
        setUserRole,
        userEmail,
        setUserEmail,
        categories,
        setCategories,
    ] = useContext(MainContext);

    const [productName, setProductName] = useState('');
    const [productDesc, setProductDesc] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const [productImage, setProductImage] = useState(null);

    useEffect(() => {
        fetch('/api/categories')
          .then(response => response.json())
          .then(data => {
            setCategories(data);
          })
          .catch(error => console.error('Ошибка при получении данных о категориях:', error));
      }, []);

    useEffect(() => {
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserRole(data.role);
            })
            .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
            .finally(() => setIsLoading(false));
    }, []);


    const handleImageChange = (e) => {
        setProductImage(e.target.files[0]);
    };

    async function AddNewProduct(e) {
        e.preventDefault();
    
        if (selectedCategory) {
            const formData = new FormData();
            formData.append('productImage', productImage);
            formData.append('productName', productName);
            formData.append('productDesc', productDesc);
            formData.append('productPrice', productPrice);
            formData.append('unit', selectedUnit);
            formData.append('category', selectedCategory);

            try {
            const response = await axios.post('/api/addNewProduct', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        
            
            if (response.status === 200) {
                alert("Товар додано успішно");
            } else {
                alert("Сталася помилка під час створювання нового товару");
            }
            } catch (error) {
            console.error('Error:', error);
            alert("Помилка мережі!");
            }
        } else {
            alert('Будь ласка, виберіть категорію');
        }
    };

    if (isLoading) {
        return <LoadingPage/>;
    }
  return (
    <>
        {userRole === "admin" ? (
                <>
                    <main>
                        <div className="container">
                            <div className="admin_panel">
                                <AdminPanelAside/>
                                <section className="admin_panel_window">
                                    <form
                                     className='admin_panel_add_new_product_form' 
                                     action='POST' 
                                     onSubmit={AddNewProduct}>
                                        <label htmlFor="productImage">
                                            Виберіть зображення:
                                        </label>
                                        <input
                                         type="file"
                                         name='productImage'
                                         onChange={handleImageChange}
                                        />
                                        <input
                                         type="text"
                                         placeholder='Назва товару'
                                         name='product_name'
                                         value={productName}
                                         required
                                         onChange={(e) => setProductName(e.target.value)}
                                        />
                                        <textarea 
                                         type="textarea" 
                                         placeholder='Опис товару' 
                                         name='product_description'
                                         value={productDesc}
                                         required
                                         onChange={(e) => setProductDesc(e.target.value)}
                                         rows={4}
                                         cols={30}
                                        />
                                        <div className="price_and_unit">
                                            <input
                                            type="text"
                                            placeholder='Ціна'
                                            name='product_price'
                                            value={productPrice}
                                            required
                                            onChange={(e) => setProductPrice(e.target.value)}
                                            />
                                            <select
                                            name='unit' 
                                            id='unit' 
                                            className='unit_select' 
                                            onChange={(e) => setSelectedUnit(e.target.value)}
                                            >
                                                <option value="шт.">шт.</option>
                                                <option value="кг.">кг.</option>
                                                <option value="л.">л.</option>
                                                <option value="мл.">мл.</option>
                                                <option value="м.">м.</option>
                                                <option value="см.">см.</option>
                                            </select>
                                        </div>
                                        {/* <select
                                         name='category' 
                                         id='category' 
                                         className='category_select' 
                                         defaultValue="all category"
                                         onChange={(e) => setSelectedCategory(e.target.value)}
                                        >
                                            <option value="all category" disabled>Виберіть Категорію</option>
                                            {categories.length > 0 ? (
                                                categories.map(category => (
                                                    <option key={category.id} value={category.id}>{category.name}</option>
                                                ))
                                            ) : (
                                                <option disabled>На данний момент категорії відсутні</option>
                                            )}
                                        </select> */}
                                        <MultilevelCategoryMenu 
                                         categories={categories} 
                                         onCategorySelect={(categoryId) => {
                                             setSelectedCategory(categoryId);
                                             console.log("Selected category ID:", categoryId);
                                         }}
                                        />
                                        <button className='admin_panel_add_new_product_button' type='submit'>Створити новий товар</button>
                                    </form>
                                </section>
                            </div>
                        </div>
                    </main>
                </>
            ) : (
                <Navigate replace to="/" />
            )}
    </>
  )
}
