import React from 'react';
import "./button.scss";

export default function Button({value}) {
  return (
    <div className='button'>
        <button type="submit">{value}</button>
    </div>
  )
}
