import React, {useState, useEffect, useContext} from "react";
import {Navigate} from 'react-router-dom';
import "../admin_panel.scss"
import AdminPanelAside from "../../../components/AdminPanelAside/AdminPanelAside";
import './statistic.scss'
import LoadingPage from "../../Loading/LoadingPage";
import {MainContext} from "../../../App";

export default function Statistic(){

    const [
        isLoading,
        setIsLoading,
        userRole,
        setUserRole,
        userEmail,
        setUserEmail,
        categories,
        setCategories,
    ] = useContext(MainContext);

    const [usersNumber, setUsersNumber] = useState('0');

    const [adminNumber, setAdminNumber] = useState('0');

    const [productNumber, setProductNumber] = useState('0');

    const [categoryNumber, setCategoryNumber] = useState('0');

    useEffect(() => {
        fetch('/api/getStatsNumbers')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUsersNumber(data.num_users);
                setAdminNumber(data.num_admins);
                setProductNumber(data.num_products);
                setCategoryNumber(data.num_categories);
            })
            .catch(error => console.error('Error of getting user number', error))
    }, []);

    useEffect(() => {
        // Выполнение запроса к серверу для получения информации о пользователе
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserRole(data.role);
            })
            .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <LoadingPage/>;
    }

    return (
        <>
            {userRole === "admin" ? (
                <>
                    <main>
                        <div className="container">
                            <div className="admin_panel">
                                <AdminPanelAside/>
                                <div className="admin_panel_window">
                                    <div className="stat_numbers">
                                        <p>Зареєстровано користувачів: {usersNumber}</p>
                                        <p>Кількість адміністраторів: {adminNumber}</p>
                                        <p>Кількість товарів: {productNumber}</p>
                                        <p>Кількість категорій: {categoryNumber}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            ) : (
                <Navigate replace to="/" />
            )}
        </>
    );
}