import React, {useState, useEffect} from 'react'
import './adminProductEdit.scss'
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function AdminProductEdit() {
  const {id} = useParams();
  const [product, setProduct] = useState(null);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    // Fetch the product details if needed, e.g., axios.get(`/api/products/${id}`)
    setProduct(id); // Set the product ID from the URL
  }, [id]);

  useEffect(() => {
    if (id) {
      axios.get(`/api/products/${id}/${id}`)
        .then(response => {
          setIsActive(response.data.is_active === 1);
        })
        .catch(error => {
          console.log('There was an error fetching the product!', error);
        });
    }
  }, [id]); // useEffect зависит от id

  async function deleteProduct(e) {
    e.preventDefault();
    try {
      console.log('Sending request to delete product:', product);
      const response = await axios.post('/api/deleteProduct', {
        product_id: product
      });

      console.log('Response from server:', response);

      if (response.status === 200) {
        alert('Продукт успішно видалено');
        window.location.href = "/";
      } else {
        alert('Сталася помилка при видаленні продукту');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Сталася помилка при видаленні продукту');
    }
  };
  async function editProduct(e) {
    e.preventDefault();
    try {
      console.log('Sending request to edit product:', product);
      const response = await axios.post('/api/editProduct', {
        product_id: product
      });

      console.log('Response from server:', response);

      if (response.status === 200) {
        alert('Продукт відредаговано видалено');
      } else {
        alert('Сталася помилка при видаленні продукту');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Сталася помилка при видаленні продукту');
    }
  };
  async function deactivateProduct(e) {
    e.preventDefault();
    try {
      console.log('Sending request to deactivate product:', product);
      const response = await axios.post('/api/deactivateProduct', {
        product_id: product
      });

      console.log('Response from server:', response);

      if (response.status === 200) {
        alert('Продукт успішно деактивовано');
        window.location.reload();
      } else {
        alert('Сталася помилка при деактивуванні продукту');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Сталася помилка при деактивуванні продукту');
    }
  };
  async function activateProduct(e) {
    e.preventDefault();
    try {
      console.log('Sending request to activate product:', product);
      const response = await axios.post('/api/activateProduct', {
        product_id: product
      });

      console.log('Response from server:', response);

      if (response.status === 200) {
        alert('Продукт успішно активовано');
        window.location.reload();
      } else {
        alert('Сталася помилка при активуванні продукту');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Сталася помилка при активуванні продукту');
    }
  };

  return (
    <>
        <div className="adminCompWrapper">
            <button onClick={editProduct}>Редагувати</button>
            <button onClick={deleteProduct}>Видалити товар</button>
            {isActive ? (<button onClick={deactivateProduct}>Деактивувати товар</button>) : (<button onClick={activateProduct}>Активувати товар</button>)}
        </div>
    </>
  )
}
