import React from 'react';

export default function Error() {
  return (
    <>
      <main className="main">
        <h1 className='error'>Error 404</h1>
        <p>Сторінка не знайдена</p>
      </main>
    </>
  );
}
