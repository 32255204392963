import React, {useState, useEffect, useContext} from 'react'
import AdminPanelAside from '../../../components/AdminPanelAside/AdminPanelAside'
import { Navigate } from 'react-router-dom';
import "./editCat.scss";
import axios from 'axios';
import LoadingPage from '../../Loading/LoadingPage';
import { MainContext } from '../../../App';
import MultilevelCategoryMenu from '../../../components/NestedCatMenu/MultilevelCategoryMenu';

export default function EditCategory() {

    const [
        isLoading,
        setIsLoading,
        userRole,
        setUserRole,
        userEmail,
        setUserEmail,
        categories,
        setCategories,
    ] = useContext(MainContext);

  const [addCategoryName, setAddCategoryName] = useState('');
  const [addSubCategoryName, setAddSubCategoryName] = useState('');

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  async function DeleteCategory(e){
    e.preventDefault();

    if (selectedCategory) {
        try {
          console.log('Sending request to delete category:', selectedCategory);
          const response = await axios.post('/api/DeleteCategory', {
            category: selectedCategory // Передаем выбранную категорию в теле запроса
          });

          console.log('Response from server:', response);

          if (response.status === 200) {
              alert('Категорію успішно видалено');
              // Возможно, обновите список категорий здесь
          } else {
              alert('Сталася помилка при видаленні категорії');
          }
        } catch (error) {
            console.error('Error:', error);
            alert('Сталася помилка при видаленні категорії');
        }
    } else {
        alert('Будь ласка, виберіть категорію');
    }
  };

  async function AddNewCategory(e) {
      e.preventDefault(); // предотвращает перезагрузку страницы
  
      try {
        const response = await axios.post('/api/AddNewCategory', {
          addCategoryName: addCategoryName,
        });
  
        const data = response.data;
        if (data.message === "Category added successfully") {
          alert("Категорію додано успішно");
        } else {
          alert("Сталася помилка під час створення нової категорії");
        }
      } catch (error) {
        console.error('Error:', error);
        alert("Помилка мережі!");
      }
  }

  async function AddNewSubCategory(e) {
    e.preventDefault();

    // if (!selectedCategory || selectedCategory.trim() === '') {
    //     alert("Будь ласка, оберіть категорію!");
    //     return;
    // }

    try {
        console.log('Sending request to add new subcategory:', {
            category: selectedCategory,
            subCategory: addSubCategoryName
        });

        const response = await axios.post('/api/AddNewSubCategory', {
            category: selectedCategory,
            subCategory: addSubCategoryName,
        });

        const data = response.data;
        if (data.message === "Subcategory added successfully") {
            alert("Підкатегорію додано успішно");
        } else {
            alert("Сталася помилка під час створення підкатегорії");
        }
        } catch (error) {
            console.error('Полная информация об ошибке:', error);
            
            if (error.response) {
              // Сервер ответил с ошибкой статуса
              console.error('Ошибка ответа:', error.response.data);
              console.error('Статус:', error.response.status);
              alert(`Ошибка: ${error.response.data.message || 'Неизвестная ошибка'}`);
            } else if (error.request) {
              // Запрос был сделан, но нет ответа
              console.error('Нет ответа от сервера');
              alert("Нет ответа от сервера");
            } else {
              // Что-то пошло не так при настройке запроса
              console.error('Ошибка настройки запроса:', error.message);
              alert("Ошибка при выполнении запроса");
            }
        }    
  }

    useEffect(() => {
        fetch('/api/categories')
        .then(response => response.json())
        .then(data => {
            setCategories(data);
        })
        .catch(error => console.error('Ошибка при получении данных о категориях:', error));
    }, []);

  useEffect(() => {
      fetch('/api/getUserInfo')
          .then(response => {
              if (!response.ok) {
                  throw new Error('Failed to fetch user info');
              }
              return response.json();
          })
          .then(data => {
              setUserRole(data.role);
          })
          .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
          .finally(() => setIsLoading(false));
  }, []);

    const handleCategoryClick = (category) => {
        if (selectedCategory === category) {
            setSelectedCategory(null);
            setSelectedSubCategory(null);
        } else {
            setSelectedCategory(category);
            setSelectedSubCategory(null);
        }
    };

    const handleSubCategoryClick = (subcategory) => {
        if (selectedSubCategory === subcategory) {
            setSelectedSubCategory(null);
        } else {
            setSelectedSubCategory(subcategory);
        }
    };

  if (isLoading) {
      return <LoadingPage/>;
  }
  return (
    <>
        {userRole === "admin" ? (
            <main>
                <div className="container">
                    <div className="admin_panel">
                        <AdminPanelAside/>
                        <div className="admin_panel_window">
                            <form
                                action="POST"
                                className='admin_panel_edit_cat_form'
                                onSubmit={DeleteCategory}
                            >
                                <label htmlFor="">Видалити категорію:</label>
                                {/* <select
                                    name='category' 
                                    id='category' 
                                    className='category_select' 
                                    defaultValue="all category"
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                >
                                    <option value="all category" disabled>Видаліть категорію</option>
                                    {categories.length > 0 ? (
                                        categories.map(category => (
                                            <option key={category.id} value={category.id}>{category.name}</option>
                                        ))
                                    ) : (
                                        <option disabled>На данний момент категорії відсутні</option>
                                    )}
                                </select> */}
                                <MultilevelCategoryMenu 
                                    categories={categories} 
                                    onCategorySelect={(categoryId) => {
                                        setSelectedCategory(categoryId);
                                        console.log("Selected category ID:", categoryId);
                                    }}
                                />
                                <button type='submit' className='admin_panel_edit_cat_button'>Видалити</button>
                            </form>
                            <form
                                action="POST"
                                className='admin_panel_edit_cat_form'
                                onSubmit={AddNewCategory}
                            >
                                <label>Додати категорію:</label>
                                <input
                                    type="text"
                                    placeholder='Назва категорії'
                                    name='add_category'
                                    value={addCategoryName}
                                    required
                                    onChange={(e) => setAddCategoryName(e.target.value)} />
                                <button type='submit' className='admin_panel_edit_cat_button'>Додати</button>
                            </form>
                            <form
                                action="POST"
                                className='admin_panel_edit_cat_form'
                                onSubmit={AddNewSubCategory}
                            >
                                <label>Додати підкатегорію:</label>
                                <MultilevelCategoryMenu 
                                    categories={categories} 
                                    onCategorySelect={(categoryId) => {
                                        setSelectedCategory(categoryId);
                                        console.log("Selected category ID:", categoryId);
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder='Назва підкатегорії'
                                    name='add_category'
                                    value={addSubCategoryName}
                                    required
                                    onChange={(e) => setAddSubCategoryName(e.target.value)} />
                                <button
                                 type='submit' 
                                 className='admin_panel_edit_cat_button'
                                 disabled={categories.length === 0}
                                >
                                    Додати
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        ) : (
            <Navigate replace to="/" />
        )}
    </>
  )
}