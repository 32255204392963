import React, { useState, useEffect, useContext } from 'react'
import './modal.scss';
import { Link } from 'react-router-dom';
import { MainContext } from '../../../App';
 
export default function Modal() {

    const toggleModal = () => {
        setModal(!modal)
    }

    const [subcategories, setSubcategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(null);

    const [
        isLoading,
        setIsLoading,
        userRole,
        setUserRole,
        userEmail,
        setUserEmail,
        categories,
        setCategories,
        modal,
        setModal,
    ] = useContext(MainContext);

    // useEffect(() => {
    //     fetch('/api/categories')
    //       .then(response => response.json())
    //       .then(data => {
    //         setCategories(data);
    //       })
    //       .catch(error => console.error('Ошибка при получении данных о категориях:', error));
    // }, []);

    useEffect(() => {
        // Запрос данных с сервера
        fetch('/api/categories')
            .then((response) => response.json())
            .then((data) => {
                setCategories(data); // Сразу используем данные в иерархическом формате
            })
            .catch((error) => console.error('Ошибка загрузки категорий:', error));
    }, []);

    const handleCategoryClick = (category) => {
        setCurrentCategory(category);
        setSubcategories(category.children || []);
    };

    return (
        <>
        {modal && (
            // <div className="modal">
            //     <div className="modal-content">
            //         <ul className='category_ul'>
            //             {categories.length > 0 ? (
            //                 categories.map(category => (
            //                     <Link className='category_link' to={`/products/${category.id}`}>
            //                         <li className='category_li' key={category.id}>{category.name}</li>
            //                     </Link>
            //                 ))
            //             ) : (
            //                 <li>На даний момент категорії відсутні</li>
            //             )}
            //         </ul>
            //         <hr className='modal-content-hr' />
            //         <div className="subcategory">

            //         </div>
            //         <span
            //          className='close-modal' 
            //          onClick={toggleModal}
            //         >&times;</span>
            //     </div>
            //     <div
            //     className='overlay'
            //     onClick={toggleModal}
            //     ></div>
            // </div>

        <div className="modal">
            <div className="modal-content">
                {/* main categories */}
                <ul className="category_ul">
                    {categories.length > 0 ? (
                        categories.map((category) => (
                            <li
                                key={category.id}
                                className="category_li"
                                onClick={() => handleCategoryClick(category)}
                            >
                                {category.name}
                            </li>
                        ))
                    ) : (
                        <li>На даний момент категорії відсутні</li>
                    )}
                </ul>

                {/* subcategories */}
                {subcategories.length > 0 && (
                    <ul className="subcategory_ul">
                        {subcategories.map((subcategory) => (
                            <li key={subcategory.id} className="subcategory_li">
                                <Link to={`/products/${subcategory.id}`}>
                                    {subcategory.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}

                <span className="close-modal" onClick={toggleModal}>
                    &times;
                </span>
            </div>
            <div className="overlay" onClick={toggleModal}></div>
        </div>
        )}
        </>
    );
}
 