import React from 'react'
import { Link } from 'react-router-dom'
import './main.scss';
import Slider from './Slider/Slider';
// import market1 from '../../img/market1.png';
// import market2 from '../../img/market2.png';
// import market3 from '../../img/market3.png';
// import NewSlider from './NewSlider/NewSlider';
import WpLogo from '../../img/wallpaper market.png'
import Button from '../Button/Button';
// import PopularCategory from './PopularCategory/PopularCategory';

export default function Main() {
  // const images = [
  //   {
  //     imgURL: market1,
  //     imgAlt: "img-1"
  //   },
  //   {
  //     imgURL: market2,
  //     imgAlt: "img-2"
  //   },
  //   {
  //     imgURL: market3,
  //     imgAlt: "img-3"
  //   },
  // ];
  return (
    <main className='main'>
      <div className='container3'>
        <Slider/>
        <Link to='/'>
          <img className='wp_logo_main' src={WpLogo} alt="Шпалерний Ринок" />
        </Link>
        <p className='wp_text_main'>В житті дуже важливо вміти вибрати шпалери </p>
        {/* <NewSlider>
          {images.map((image, index) => {
            return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
          })}
        </NewSlider> */}
        {/* <h2 className='popular_category'>Популярні категорії</h2>
        <section className='category'>
          <PopularCategory/>
          <PopularCategory/>
          <PopularCategory/>
        </section> */}
      </div>
    </main>
  );
}