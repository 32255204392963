import React, { useContext, useEffect } from 'react';
import {Link, Navigate} from 'react-router-dom';
// import Button from '../../components/Button/Button';
import axios from 'axios';
import LoadingPage from '../Loading/LoadingPage';
import './reg.scss'
import { MainContext } from '../../App';

export default function Registration() {

  const [
      isLoading,
      setIsLoading,
      userRole,
      setUserRole,
      userEmail,
      setUserEmail,
      categories,
      setCategories,
  ] = useContext(MainContext);
  
  useEffect(() => {
    // Выполнение запроса к серверу для получения информации о пользователе
    fetch('/api/getUserInfo')
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch user info');
            }
            return response.json();
        })
        .then(data => {
            setUserEmail(data.email); // Установка электронной почты пользователя в состояние
        })
        .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
        .finally(() => setIsLoading(false));
  }, []);

//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [confirm_pass, setConfirmPass] = useState('');

  axios.defaults.withCredentials = true;

//   async function handleSubmit(e) {
//     e.preventDefault(); // предотвращает перезагрузку страницы

//     if (password !== confirm_pass) {
//       alert("Увага! Паролі не збігаються!");
//       return;
//     }
//     if (password.length < 4){
//       alert("Увага! Пароль має бути довшим ніж 4 символи!");
//       return;
//     }

//     try {
//       const response = await axios.post('/api/register', {
//         email: email,
//         password: password,
//       });

//       const data = response.data;
//       if (data.message === "User already exists") {
//         alert("Даний користувач вже існує");
//       } else if (data.message === "User registered successfully") {
//         window.location.href = "http://localhost:3000/registration_success";
//       } else {
//         alert("Сталася помилка під час реєстрації");
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert("Помилка мережі!");
//     }
//   }
  
  if (isLoading) {
    return <LoadingPage/>;
  }
  return (
    <>
      {userEmail ? (
        <Navigate replace to="/" />
      ):(
      <>
          <main className="main">
            <div className="reg_text_con">
              <h1 className='reg_text'>На разі реєстрація звичайних користувачів не доступна!</h1>
              <p className='reg_text2'>&nbsp;У вас є акаунт?<Link to='/login'>Увійти</Link></p>
            </div>
              {/* <form className="registration" onSubmit={handleSubmit} action='POST'>
                <h4>Реєстрація</h4>
                <input 
                name='email' 
                type="email" 
                placeholder="Е-Пошта" 
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
                <input 
                name='password' 
                type="password" 
                placeholder="Пароль" 
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                />
                <input 
                name='password_confirm' 
                type="password" 
                placeholder="Підтвердіть пароль" 
                required
                value={confirm_pass}
                onChange={(e) => setConfirmPass(e.target.value)}
                />
                <Button type="submit" value={"Створити профіль"}/>
                <p>Є акаунт?<Link to='/login'>Увійти</Link></p>
              </form> */}
          </main>
      </>
      )}
    </>
  );
}