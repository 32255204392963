import React, {useState, useEffect} from "react";
import {Navigate} from 'react-router-dom';
import "./changePassword.scss"
import axios from "axios";
import AdminPanelAside from "../../../components/AdminPanelAside/AdminPanelAside";
import LoadingPage from "../../Loading/LoadingPage";

export default function ChangePassword(){

  const [password, setPassword] = useState('');
  const [confirm_pass, setConfirmPass] = useState('');

  axios.defaults.withCredentials = true;

  async function updatePassword(e) {
    e.preventDefault();

    if (password !== confirm_pass) {
      alert("Увага! Паролі не збігаються!");
      return;
    }
    if (password.length < 4){
      alert("Увага! Пароль має бути довшим ніж 4 символи!");
      return;
    }

    try {
      const response = await axios.post('/api/updateUserPassword', {
        password: password,
      });

      const data = response.data;
      if (data.message === "Password updated") {
        alert("Пароль змінено успішно");
      }else {
        alert("Сталася помилка під час зміни паролю");
      }
    } catch (error) {
      console.error('Error:', error);
      alert("Помилка мережі!");
    }
  }

    const [userRole, setUserRole] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserRole(data.role);
            })
            .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <LoadingPage/>;
    }

    return (
        <>
            {userRole === "admin" ? (
                <>
                    <main>
                        <div className="container">
                            <div className="admin_panel">
                                <AdminPanelAside/>
                                <section className="admin_panel_window">
                                    <p className="admin_panel_change_pass_text">Оновити пароль: </p>
                                    <form className="admin_panel_change_pass_form" onSubmit={updatePassword} action="POST">
                                        <input 
                                            name='password' 
                                            type="password" 
                                            placeholder="Пароль" 
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <input 
                                            name='password_confirm' 
                                            type="password" 
                                            placeholder="Підтвердіть пароль" 
                                            required
                                            value={confirm_pass}
                                            onChange={(e) => setConfirmPass(e.target.value)}
                                        />
                                        <button type="submit" className="admin_panel_change_pass_form_button">Оновити пароль</button>
                                    </form>
                                </section>
                            </div>
                        </div>
                    </main>
                </>
            ) : (
                <Navigate replace to="/" />
            )}
        </>
    );
}