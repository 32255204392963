import React, {useState, useEffect, useContext} from 'react';
// import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import axios from "axios";
import Button from '../../components/Button/Button';
import LoadingPage from '../Loading/LoadingPage';
import { MainContext } from '../../App';

export default function Login() {

      const [
          isLoading,
          setIsLoading,
          userRole,
          setUserRole,
          userEmail,
          setUserEmail,
          categories,
          setCategories,
      ] = useContext(MainContext);
  useEffect(() => {
    fetch('/api/getUserInfo')
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch user info');
            }
            return response.json();
        })
        .then(data => {
            setUserEmail(data.email);
        })
        .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
        .finally(() => setIsLoading(false));
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  axios.defaults.withCredentials = true; // Установка флага с withCredentials для axios для передачи куки в запросах

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await axios.post('/api/login', {
        email: email,
        password: password,
      });

      const data = response.data;
      if (data.message === "Authentication successful, user has role of admin") {
        window.location.href = "/";
      }
      else if (data.message === "Authentication successful") {
        window.location.href = "/";
      }
      else if (data.message === "Wrong email/password combination!") {
        alert("Невірний email чи пароль!");
      } 
      else if (data.message === "User doesn't exist"){
        alert("Такого користувача не існує");
      }
    } catch (error) {
      console.error('Error:', error);
      alert("Помилка мережі!");
    }
  }

  if(isLoading){
    return <LoadingPage/>;
  }

  return (
    <>
      {userEmail ? (
        <Navigate replace to="/" />
      ):(
        <>
          <main className="main">
              <form className="login" onSubmit={handleSubmit} action="POST">
                  <label>Вхід</label>
                  <input 
                      name="email" 
                      placeholder="Е-Пошта" 
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  />
                  <input 
                      name="password" 
                      type="password" 
                      placeholder="Пароль" 
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <div className="remember_forgot">
                      <div className="login-checkbox-label">
                          <input 
                              type="checkbox" 
                              id="rememberMe"
                          />
                          <label htmlFor="rememberMe">Запам'ятати мене</label>
                      </div>
                      <Link to="/"><ins>Забули пароль?</ins></Link>
                  </div> */}
                  <Button type="submit" value="Увійти" />
                  {/* <p>Немає акаунту?<Link to="/registration">Зареєструватись</Link></p> */}
              </form>
          </main>
        </>
      )}
    </>
  )
}