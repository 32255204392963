import React from 'react'
import './product.scss'
import { Link } from 'react-router-dom'

export default function Product({ product, categoryName }) {

  // Base URL for the server
  const baseURL = "https://api.wallpapermarket.org/";

  return (
    <div className='product_container'>
      {product ? (
        <Link to={`/product/${product.product_id}`} className='product_link'>
          <img className='product_image' src={`${baseURL}${product.image_path}`} alt="product_image" />
          <section className='product_info'>
            <p className='product_name'>{product.name}</p>
            <p className='product_description'>{product.description}</p>
            <p className='product_price'>{product.price} грн/{product.unit}</p>
            <p className='product_category'>{categoryName}</p>
          </section>
        </Link>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}
