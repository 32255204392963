import React, {useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { MainContext } from '../../App';

export default function AdminPanelAside() {

    const [
        isLoading,
        setIsLoading,
        userRole,
        setUserRole,
        userEmail,
        setUserEmail,
        categories,
        setCategories,
    ] = useContext(MainContext);

    const deleteCookie = async () => {
        try {
            const response = await axios.post('/api/delete-cookie', {}, {
                withCredentials: true // Включаем куки в запрос
            });

            if (response.status === 200) {
                window.location.reload();
            } else {
                alert('Failed to delete cookie');
            }
        } catch (error) {
            console.error('Error deleting cookie:', error);
            alert('Error deleting cookie');
        }
    };

    useEffect(() => {
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserEmail(data.email);
            })
            .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
    }, []);

  return (
    <>
        <div className="admin_panel_aside">
            <Link to="/admin-panel">
                <h1>Панель Адміністратора</h1>
            </Link>
            <ul>
                <Link to="/admin-panel-statistic" className="admin_panel_link">
                    <li>
                        Статистика
                    </li>
                </Link>
                <Link to="/admin-panel-add-new-product" className="admin_panel_link">
                    <li>
                        Додати новий товар
                    </li>
                </Link>
                <Link to="/admin-panel-edit-category" className="admin_panel_link">
                    <li>
                        Категорії
                    </li>
                </Link>
                <Link to="/admin-panel-change-pass" className="admin_panel_link">
                    <li>
                        Змінити пароль
                    </li>
                </Link>
                <li onClick={deleteCookie}>
                    <p className="admin_panel_link">Вийти з профілю</p>
                </li>
            </ul>
            <h2>{userEmail}</h2>
        </div>
    </>
  )
}
