import React, {useState, createContext} from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header/Header"
import Main from "./components/Main/Main"
import Footer from "./components/Footer/Footer"
import Registration from "./pages/Registration/Registration";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import Login from "./pages/Login/Login";
import RegSuccess from "./pages/RegSuccess/RegSuccess";
import Products from "./pages/Products/Products";
import ProductPage from "./pages/ProductPage/PoductPage"
import Account from "./pages/Account/Account";
import ChangePassword from "./pages/AdminPanel/ChangePassword/ChangePassword";
import Statistic from "./pages/AdminPanel/Statistic/Statistic";
import AddNewProduct from "./pages/AdminPanel/AddNewProduct/AddNewProduct";
import EditCategory from "./pages/AdminPanel/EditCategory/EditCategory";
import LoadingPage from "./pages/Loading/LoadingPage";
import Error from './pages/Error/Error';
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {

  const location = useLocation();

  return (
      <AnimatePresence mode="wait">
          <Routes
           location={location} 
           key={location.pathname}
          >
              <Route
                  path="/" 
                  element={
                    <Main />
                  }
              />
              <Route
                  path="/registration" 
                  element={
                    <Registration />
                  } 
              />
              <Route
                  path="/admin-panel" 
                  element={
                    <AdminPanel />
                  } 
              />
              <Route
                  path="/login" 
                  element={
                    <Login />
                  } 
              />
              <Route
                  path="/registration_success" 
                  element={
                    <RegSuccess />
                  } 
              />
              <Route
                  path="/products/:id" 
                  element={
                    <Products />
                  } 
              />
              <Route
                  path="/product/:id" 
                  element={
                    <ProductPage />
                  } 
              />
              <Route
                  path="/account" 
                  element={
                    <Account />
                  } 
              />
              <Route
                  path="/admin-panel-change-pass" 
                  element={
                    <ChangePassword />
                  } 
              />
              <Route
                  path="/admin-panel-statistic" 
                  element={
                    <Statistic />
                  } 
              />
              <Route
                  path="/admin-panel-add-new-product" 
                  element={
                    <AddNewProduct />
                  } 
              />
              <Route
                  path="/admin-panel-edit-category" 
                  element={
                    <EditCategory />
                  } 
              />
              <Route
                  path="*"
                  element={<Error />}
              />
          </Routes>
      </AnimatePresence>
  );
}

export const MainContext = createContext(null);

export default function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);

  return (
      <Router>
          <MainContext.Provider
              value={[
                isLoading,
                setIsLoading,
                userRole,
                setUserRole,
                userEmail,
                setUserEmail,
                categories,
                setCategories,
                modal,
                setModal,
              ]}
          >
              <Header />
              <AnimatedRoutes />
              <Footer />
          </MainContext.Provider>
      </Router>
  );
}
