import React, {useContext, useEffect} from "react";
import {Link, Navigate} from 'react-router-dom';
import wallpaper_market from '../../img/wallpaper market.svg';
import "./admin_panel.scss"
import AdminPanelAside from "../../components/AdminPanelAside/AdminPanelAside";
import LoadingPage from '../Loading/LoadingPage'
import { MainContext } from "../../App";

export default function AdminPanel(){

    const [
        isLoading,
        setIsLoading,
        userRole,
        setUserRole,
    ] = useContext(MainContext);

    useEffect(() => {
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserRole(data.role);
            })
            .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <LoadingPage/>;
    }

    return (
        <>
            {userRole === "admin" ? (
                <>
                    <main>
                        <div className="container">
                            <div className="admin_panel">
                                <AdminPanelAside/>
                                <section className="admin_panel_window">
                                    <Link to="/">
                                        <img alt="Логотип шпалерного ринку" className='wallpaper_market_logo' src={wallpaper_market}/>
                                    </Link>
                                </section>
                            </div>
                        </div>
                    </main>
                </>
            ) : (
                <Navigate replace to="/" />
            )}
        </>
    );
}