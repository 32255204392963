import React, { useState, useEffect } from 'react';
import DropDownArrow from "../../img/dropdown-arrow.svg";
import "./MCM.scss"

// const CategoryItem = ({ category, selectedCategoryIds, onCategoryClick, depth = 0 }) => {
//   const [isExpanded, setIsExpanded] = useState(false);
//   const hasChildren = category.children && category.children.length > 0;
//   const isSelected = selectedCategoryIds.includes(category.id);
  
//   const indentStyle = {
//     paddingLeft: `${depth * 16}px`
//   };

//   const handleClick = () => {
//     onCategoryClick(category);
//     if (hasChildren) {
//       setIsExpanded(!isExpanded);
//     }
//   };

//   return (
//     <>
//       <li 
//         className={`category_li ${isSelected ? 'active' : ''}`} 
//         style={indentStyle}
//         onClick={handleClick}
//       >
//         <div className="category_item_content">
//           <span>{category.name}</span>
//         </div>
//       </li>
      
//       {/* Рекурсивно отображаем дочерние категории, если они есть и если раздел развернут */}
//       {/* {hasChildren && isExpanded && (
//         <ul className="category_children">
//           {category.children.map(child => (
//             <CategoryItem
//               key={child.id}
//               category={child}
//               selectedCategoryIds={selectedCategoryIds}
//               onCategoryClick={onCategoryClick}
//               depth={depth + 1}
//             />
//           ))}
//         </ul>
//       )} */}
      
//     </>
//   );
// };

// Главный компонент меню категорий

const CategoryItem = ({ category, selectedCategory, onCategoryClick }) => {
  const hasChildren = category.children && category.children.length > 0;
  const isSelected = selectedCategory && selectedCategory.id === category.id;

  return (
    <li 
      className={`category_li ${isSelected ? 'active' : ''}`} 
      onClick={() => onCategoryClick(category)}
    >
      {category.name}
    </li>
  );
};

// const MultilevelCategoryMenu = ({ categories, onCategorySelect }) => {
//   const [subCategoryDropDown, setSubCategoryDropDown] = useState(false);
//   const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  
//   const handleCategoryClick = (category) => {
//     // Управление выбранными категориями
//     // Здесь вы можете реализовать разную логику в зависимости от требований:
//     // - выбор только одной категории
//     // - добавление/удаление категорий из выбранных
//     // - выбор только конечных категорий (без детей) и т.д.
    
//     // Пример: выбор только одной категории
//     if (selectedCategoryIds.includes(category.id)) {
//       setSelectedCategoryIds([]);
//       onCategorySelect(null); // Сбрасываем выбор
//     } else {
//       setSelectedCategoryIds([category.id]);
//       onCategorySelect(category); // Передаем выбранную категорию родительскому компоненту
//     }
//   };

//   return (
//     <div className="subcaterogy_menu">
//       <div 
//         className="category_title_con"
//         onClick={() => setSubCategoryDropDown(!subCategoryDropDown)}
//       >
//         <div className="category_title">Категорії</div>
//         <img
//           className={`dropdown_arrow_img ${subCategoryDropDown ? 'active' : ''}`}
//           src={DropDownArrow}
//           alt="dropdown"
//         />
//       </div>
      
//       {subCategoryDropDown && (
//         <div className="drop_down_con">
//           <ul className="drop_down_menu">
//             {categories.length > 0 ? (
//               categories.map(category => (
//                 <CategoryItem
//                   key={category.id}
//                   category={category}
//                   selectedCategoryIds={selectedCategoryIds}
//                   onCategoryClick={handleCategoryClick}
//                 />
//               ))
//             ) : (
//               <li>На даний момент категорії відсутні</li>
//             )}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };

const MultilevelCategoryMenu = ({ categories, onCategorySelect }) => {
  const [subCategoryDropDown, setSubCategoryDropDown] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Log the current selected category immediately when it changes
  useEffect(() => {
    if (selectedCategories.length > 0) {
      const currentCategory = selectedCategories[selectedCategories.length - 1];
      console.log('Selected category:', currentCategory);
      // Pass the current category to the parent component
      onCategorySelect(currentCategory.id);
    }
  }, [selectedCategories, onCategorySelect]);
  
  const handleCategoryClick = (category, depth) => {
    // Обрезаем массив выбранных категорий на текущем уровне
    const newSelected = selectedCategories.slice(0, depth);
    newSelected[depth] = category;
    onCategorySelect(category);
    setSelectedCategories(newSelected);
  };

  return (
    <div className="subcaterogy_menu">
      <div 
        className="category_title_con"
        onClick={() => (
          setSubCategoryDropDown(!subCategoryDropDown),
          setSelectedCategories([])
        )}
      >
        <div className="category_title">Категорії</div>
        <img
          className={`dropdown_arrow_img ${subCategoryDropDown ? "active" : ""}`}
          src={DropDownArrow}
          alt="dropdown"
        />
      </div>
      
      {subCategoryDropDown && (
        <div className="drop_down_con">
          {[categories, ...selectedCategories.map(c => c.children)].map((level, index) => (
            level && (
              <ul key={index} className="category_column">
                {level.map(category => (
                  <CategoryItem
                    key={category.id}
                    category={category}
                    selectedCategory={selectedCategories[index]}
                    onCategoryClick={(cat) => handleCategoryClick(cat, index)}
                  />
                ))}
              </ul>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default MultilevelCategoryMenu;